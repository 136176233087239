import axios from "axios";

function initialState() {
  return {
    notifications: [],
    allNotifications: [],
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_ALL_NOTIFICATIONS(state, val) {
      state.allNotifications = val;
    },
    SET_NOTIFICATIONS(state, val) {
      state.notifications = val;
    },

    RESET_NOTIFICATION_STATE: (state) => {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    resetNotifications({ commit }) {
      commit("RESET_NOTIFICATION_STATE");
    },
    
    getNotifications({ commit }, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications`,
          params: params,
        };
        axios(options)
          .then((res) => {
            console.log("Notifications", res);
            commit("SET_NOTIFICATIONS", res.data.data.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAllNotifications({ commit }, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications`,
          params: { ...params, dont_paginate: true },
        };
        axios(options)
          .then((res) => {
            // console.log("All Notifications", res);
            commit("SET_ALL_NOTIFICATIONS", res.data.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getNotification({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications/${id}/`,
        };
        axios(options)
          .then((res) => {
            let newNotification = res.data;
            if (
              !state.notifications.some(
                (notification) =>
                  notification.notification_id ===
                  newNotification.notification_id
              )
            ) {
              let newNotifications = state.notifications.push(newNotification);
              commit("SET_ALL_NOTIFICATIONS", newNotifications);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    deleteNotification({ commit }, id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications/${id}`,
        };
        axios(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    markNotificationAsRead({ commit }, id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "PATCH",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications/notification/${id}/mark-as-read/`,
        };
        axios(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    markAllNotificationsAsRead({ commit }) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/notifications/mark-all-as-read`,
        };
        axios(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {},
};
