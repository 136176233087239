export default {
  name: "UserMixins",

  methods: {
    addUserToTenant(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("role_id", obj.role.selected);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/add/user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUsers(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCurrentUserDetails() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCurrentUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstName);
        bodyFormData.append("lastname", obj.lastName);
        // bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department_id);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user/${obj.userId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setUserActiveStatus(ssoId, status) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("sso_id", ssoId);
        bodyFormData.append("status", status);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user-status`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    reInviteVendorUser(user){
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData(); 
        bodyFormData.append("user_id", user.user_id);
        bodyFormData.append("redirect_to", 'tprms');
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/reinvite-user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
};
