import { $themeBreakpoints } from "@themeConfig";
import axios from "axios";
import moment from "moment";

function initialState() {
  if (
    window.localStorage.getItem("TPRM_SHARING_REQUESTED_SESSION") &&
    window.localStorage.getItem("TPRM_SHARING_REQUESTED_SESSION") != null
  ) {
    let newState = {
      windowWidth: 0,
      ...JSON.parse(
        window.localStorage.getItem("TPRM_SHARING_REQUESTED_SESSION")
      ),
    };
    newState["isSessionProvider"] = false;
    window.localStorage.removeItem("TPRM_SHARING_REQUESTED_SESSION");
    return newState;
  } else {
    return {
      windowWidth: 0,
      shallShowOverlay: false,
      isSessionProvider: true,
      // isTwoFAEnabled: false,
      status: "",
      token: null,
      accessToken: null,
      tenantId: null,
      loggedInTime: null,
      user: {
        id: "",
        username: "",
        groups: [],
        fullname: "",
        email: "",
        is_active: false,
        is_staff: false,
        first_name: "",
        last_name: "",
        roles: [],
        profile: {
          organization: null,
          is_organization_owner: false,
          o_profile: null,
        },
        permissions: [],
      },
      isAdmin: false,
      userId: 0,
      uid: null,
      workspaces: null,
      currentWorkspace: null,
      currentWorkspaceModules: [],
      tenantid: null,
      currentcid: null,
      tokenExpiry: null,
      twofastatus: null,
      enabled_modules: [],
    };
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    isLoggedIn: (state) => {
      if (!!state.token) {
        return true;
      } else if ($cookies.get("token") && $cookies.get("token") != "null") {
        return true;
      }
      return false;
    },
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    workspaces: (state) => state.workspaces,
    tenantid: (state) => state.tenantid,
    tokenExpiry: (state) => state.tokenExpiry,
  },
  mutations: {
    RESET_STATE: (state) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      const s = initialState();
      Object.keys(s).forEach((key) => {
        if (exludedList.includes(key) == false) {
          state[key] = s[key];
        }
      });
    },

    UPDATE_SESSION_PROVIDER_STATUS(state, val) {
      state.isSessionProvider = val;
    },

    SET_CURRENT_WORKSPACE_MODULES: (state, payload) => {
      state.currentWorkspaceModules = payload;
    },

    // SET_TWO_FA_STATUS(state, payload) {
    //   state.isTwoFAEnabled = payload;
    // },

    SET_UID(state, payload) {
      $cookies.set("uid", payload, null, null, process.env.VUE_APP_DOMAIN);
      state.uid = payload;
    },

    UPDATE_STATE: (state, newState) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      if (newState && newState != null && typeof newState != "undefined") {
        Object.keys(newState).forEach((key) => {
          if (exludedList.includes(key) == false) {
            state[key] = newState[key];
          }
        });
      }
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    setLastRequestTime: (state, lastrequesttime) => {
      state.lastrequesttime = lastrequesttime;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      state.status = "success";
      state.loggedInTime = new Date();
      state.token = data.token;
      state.tenantId = data.primary_tenant_id;
      state.accessToken = data.user.access_token;
    },
    auth_error(state) {
      state.status = "error";
    },
    auth_isAdmin(state) {
      state.isAdmin = true;
    },
    set_userData(state, user) {
      state.user = user;
    },
    set_userAttributes(state, permissions) {
      state.user.permissions = permissions || null;
    },
    logout(state) {
      state.status = "";
      state.token = null;
      state.isAdmin = false;
      state.tenantId = null;
      state.accessToken = null;
      state.user = {
        id: "",
        username: "",
        groups: [],
        fullname: "",
        email: "",
        is_active: false,
        is_staff: false,
        firstname: "",
        lastname: "",
        profile: {
          organization: null,
          is_organization_owner: false,
          o_profile: null,
        },
        permissions: [],
        workspaces: null,
      };
    },
    setWorkspaces: (state, workspaces) => {
      let newWorkspaces = [];

      if (workspaces != null) {
        workspaces.map((item) => {
          if (item.enabled_modules.constructor === Object)
            item.enabled_modules = [item.enabled_modules];

          item.enabled_modules.map((module) => {
            if ("name" in module) {
              if (module.name == "Third Party Risk Management")
                newWorkspaces.push(item);
            }
          });
        });
      }

      state.workspaces = newWorkspaces;
    },
    SET_CURRENT_WORKSPACE: (state, payload) => {
      state.currentWorkspace = payload;
    },
    setTenantid: (state, tenantid) => {
      // $cookies.set("cid", tenantid, null, null, process.env.VUE_APP_DOMAIN);
      state.tenantId = tenantid;
    },
    setToken: (state, token) => {
      $cookies.set("token", token, null, null, process.env.VUE_APP_DOMAIN);
      state.token = token;
      state.accessToken = token;
    },
    setCurrentCID: (state, currentcid) => {
      $cookies.set(
        "currentCID",
        currentcid,
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      state.currentcid = currentcid;
    },
    setTokenExpiry: (state, tokenExpiry) => {
      state.tokenExpiry = tokenExpiry;
    },
    set2Fastatus: (state, twofastatus) => {
      state.twofastatus = twofastatus;
    },
    setEnabled_modules: (state, enabled_modules) => {
      state.enabled_modules = enabled_modules;
    },
  },
  actions: {
    ReloadBasedonCookie({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
        };
        axios(options)
          .then((resp) => {
            console.log("Current User RESP", resp);
            // eslint-disable-next-line no-shadow
            const user = {
              firstname: resp.data.data.firstname,
              lastname: resp.data.data.lastname,
              mobile: resp.data.data.mobile,
              email: resp.data.data.email,
              user_id: resp.data.data.user_id,
              user_type: resp.data.data.user_type,
              roles: resp.data.data.roles || [],
              permissions: resp.data.data.permissions || [],
            };
            if (
              resp.data.data.vendor_id &&
              typeof resp.data.data.vendor_id != "undefined" &&
              resp.data.data.vendor_id !== null
            ) {
              user["vendor_id"] = resp.data.data.vendor_id;
            }
            const workspaces = resp.data.data.workspaces;
            const currentid = state.currentcid;
            console.log("Login CurrentId", currentid, Date.now());
            if (workspaces.some((space) => space.tenant_uid === currentid)) {
              commit("setTenantid", currentid);
            } else {
              commit("setTenantid", resp.data.data.primary_tenant_id);
            }

            // commit("auth_success", resp.data.data);
            commit("SET_UID", resp.data.data.user_id);
            commit("set_userData", user);
            commit("set_userAttributes", user.permissions);
            commit("setWorkspaces", workspaces);
            // commit("setToken", token)
            // axios.defaults.headers.common.Authorization = token
            var expires_at = moment().add(14, "minutes");
            dispatch("setExpiry", expires_at);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (user.email) {
          bodyFormData.append("email", user.email);
          bodyFormData.append("password", user.password);
        } else {
          bodyFormData.append("first_auth_token", user.first_auth_token);
          bodyFormData.append("one_time_password", user.one_time_password);
        }

        commit("auth_request");
        axios({
          url: `${process.env.VUE_APP_BASEURL}/auth/login`,
          data: bodyFormData,
          method: "POST",
        })
          .then((resp) => {
            if (
              resp.data.data.data &&
              resp.data.data.data.first_auth_token &&
              resp.data.data.data.is_2fa_enabled &&
              resp.data.data.data.is_2fa_enabled == 1
            ) {
              resolve(resp);
            } else {
              const { token } = resp.data.data;
              const { user } = resp.data.data;
              const workspaces = resp.data.data.workspaces;

              user["user_id"] = user._id;
              commit("auth_success", resp.data.data);
              commit("SET_UID", user._id);
              commit("set_userData", user);
              commit("set_userAttributes", resp.data.data.user.permissions);
              commit("setWorkspaces", workspaces);
              commit("setToken", token);
              commit("set2Fastatus", user.is_2fa_enabled);
              commit("setEnabled_modules", resp.data.data.enabled_modules);
              axios.defaults.headers.common.Authorization = token;
              var expires_at = moment().add(14, "minutes");
              dispatch("setExpiry", expires_at);
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            // localStorage.removeItem('token')
            reject(err);
          });
      });
    },
    setExpiry({ commit }, exp) {
      commit("setTokenExpiry", exp);
    },
    checkTokenExpiry({ dispatch, state }) {
      if (moment().format() >= moment(state.tokenExpiry).format()) {
        dispatch("refreshtoken");
        // var expires_at =  moment().add(14, "minutes");
        // dispatch("setExpiry", expires_at);
      }
      console.log(
        moment().format() +
          "/" +
          moment(state.tokenExpiry).format() +
          "its a time compare"
      );
    },
    
    refreshtoken({ commit, dispatch }) {
      axios({ url: `${process.env.VUE_APP_BASEURL}/auth/refresh-token`, method: "POST" })
        .then((resp) => {
          console.log(resp);
          const accesstoken = resp.data.data.token;
          commit("setToken", accesstoken);
          var expires_at = moment().add(14, "minutes");
          dispatch("setExpiry", expires_at);
        })
        .catch((err) => {});
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("setToken", null);
        commit("SET_UID", null);
        commit("RESET_STATE");
        delete axios.defaults.headers.common.Authorization;
        sessionStorage.clear();
        // localStorage.removeItem('accessToken')
        // localStorage.removeItem('loggedInTime')

        resolve();
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", user.firstname);
        bodyFormData.append("lastname", user.lastname);
        bodyFormData.append("company", user.company);
        bodyFormData.append("mobile", user.mobile);
        bodyFormData.append("email", user.email);
        // bodyFormData.append('password', user.password)

        commit("auth_request");
        axios({
          url: `${process.env.VUE_APP_BASEURL}/customer/sign-up`,
          data: bodyFormData,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.status === "error") {
              reject(resp);
            } else resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("password", user.password);
        bodyFormData.append("password_confirmation", user.password);
        bodyFormData.append("token", user.token);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/forget-password/reset`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    forgotPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("email", user.email);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/forget-password/email`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    createPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("password", user.password);
        bodyFormData.append("password_confirmation", user.password);
        bodyFormData.append("token", user.token);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/firstpassword`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    setCurrentUserDetails({ commit }, user) {
      commit("set_userData", user);
    },
    getCurrentUserDetails({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
          method: "GET",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    refresh({ commit }, token) {
      return new Promise((resolve) => {
        const data = {
          token,
        };
        axios({
          url: `${process.env.VUE_APP_BASEURL}/api-token-refresh/`,
          data,
          method: "POST",
        }).then((resp) => {
          // eslint-disable-next-line no-shadow
          const { token } = resp.data;
          const user = resp.data.userData;
          // localStorage.setItem('token', token)
          localStorage.setItem("userid", user.id);
          localStorage.setItem("loggedInTime", new Date());
          axios.defaults.headers.common.Authorization = token;
          commit("auth_success", token);
          commit("set_userData", user);
          commit("set_userAttributes", resp.data.permissions);
          if (user.is_staff) {
            commit("auth_isAdmin");
          }
          resolve(resp);
        });
      });
    },

    changetenant({ commit, dispatch }, payload) {
      const currentModules = [];
      payload.enabled_modules.map((item) => {
        currentModules.push(item.name);
      });

      return new Promise((resolve) => {
        commit("SET_CURRENT_WORKSPACE_MODULES", currentModules);
        commit("SET_CURRENT_WORKSPACE", payload);
        commit("setTenantid", payload.tenant_uid);
        commit("setCurrentCID", payload.tenant_uid);
        resolve();
      });
    },
  },
};
