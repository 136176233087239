// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    // appName: 'CisoGRC',  Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    // appLogoImage: require('@/assets/images/alllogos/TPRM.png'),
    // appDarkLogoImage: require('@/assets/images/alllogos/TPRM.png'),
    // appNameImage: require('@/assets/images/logo/TPRM-noemblem.png'),
    // appDarkNameImage: require('@/assets/images/logo/TPRM-white-noemblem.png'),

    ///New
    appLightLogo: require("@/assets/images/logo/tprm_light.png"),
    appDarkLogo: require("@/assets/images/logo/tprm_dark.png"),
    appEmblemLight: require("@/assets/images/logo/emblem_light.png"),
    appEmblemDark: require("@/assets/images/logo/emblem_dark.png"),
    appTextLightLogo: require("@/assets/images/logo/tprm_light_text.png"),
    appTextDarkLogo: require("@/assets/images/logo/tprm_dark_text.png"),
    secusyLightLogo:require("@/assets/images/alllogos/secusy-gradient-light.png"),
    secusyDarkLogo:require("@/assets/images/alllogos/secusy-gradient-dark.png"),
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: true,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
}
