import { isUserLoggedIn } from "@/auth/utils";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

function checkIfUserIsVendor() {
  if (
    store.state.app.user.user_type &&
    store.state.app.user.user_type == "Vendor"
  ) {
    return true;
  } else {
    return false;
  }
}
function checkIfUserIsGrc(){
  if (
    store.state.app.user.user_type &&
    store.state.app.user.user_type === "GRC_user"
  ) {
    return true;
  } else {
    return false;
  }
}

function guardRoute(to, from, next) {
  let isAuthenticated = false;
  if (store.getters["app/isLoggedIn"]) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    if(to.meta.GrcAuth !== true && checkIfUserIsGrc()){
      next("/tasks");
    }
    else if (to.meta.vendorAuth !== true && checkIfUserIsVendor()) {
      next("/vendorassessments");
    }
    next();
  } else {
    next("/login"); // go to '/login';
  }
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/NewDashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        // vendorAuth: true,
        // breadcrumb: [
        //   {
        //     text: "Stats & Graphs",
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/responses",
      name: "responses",
      component: () => import("@/views/AllResponses/AllResponsesListing.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        breadcrumb: [
          {
            text: "Risks",
            active: true,
          },
        ],
      },
    },

    {
      path: "/app-switch-landing",
      name: "app-switch-landing",
      component: () => import("@/views/AppSwitchLanding/AppSwitchLanding.vue"),
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/assets",
      name: "assets",
      component: () => import("@/views/Assets/AssetPage.vue"),
      beforeEnter: guardRoute,
      meta: {
        pageTitle: "Asset Register",
        breadcrumb: [
          {
            text: "Asset Register",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets/:id/details",
      name: "asset-details",
      component: () => import("@/views/Assets/AssetDetailsPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Asset Details",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/2FA/:id",
      name: "TwoFA",
      component: () => import("@/views/Users/TwoFA.vue"),
      meta: {
        layout: "full",
      },
    },
    // {
    //   path: '/assets/:id/details/create-custom-risk',
    //   name: 'create-custom-risk',
    //   component: () => import('@/views/RiskRegister/RiskAdd.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Create Custom Risk',
    //     breadcrumb: [
    //       {
    //         text: 'Assets',
    //         to: { name: 'assets' },
    //       },
    //       {
    //         text: 'Asset Details',
    //         to: { name: 'asset-details' },
    //       },
    //       {
    //         text: 'Custom Risk',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/assets/:id/edit",
      name: "asset-edit",
      component: () => import("@/views/Assets/AssetEditPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Edit Asset",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets/add",
      name: "asset-add",
      component: () => import("@/views/Assets/AssetAddPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Create Asset",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/risk-register',
    //   name: 'risk-register',
    //   component: () => import('@/views/RiskRegister/RiskRegister.vue'),
    //   beforeEnter: guardRoute,
    //   meta: {
    //     pageTitle: 'Risk Register',
    //     breadcrumb: [
    //       {
    //         text: 'Risk Register',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/risk-register/assets/:id/details",
      name: "risk-register-asset-details",
      component: () => import("@/views/Assets/AssetDetailsPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Asset Details",
        breadcrumb: [
          {
            text: "Risk Register",
            to: { name: "risk-register" },
          },
          {
            text: "Asset",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/risk-register/:id/risk-details',
    //   name: 'risk-details',
    //   component: () => import('@/views/RiskRegister/RiskDetails.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Risk Details',
    //     breadcrumb: [
    //       {
    //         text: 'Risk Register',
    //         to: { name: 'risk-register' },
    //       },
    //       {
    //         text: 'Risk',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/riskassessment',
    //   name: 'riskassessment',
    //   component: () => import('@/views/RiskAssessment/Listing.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Risk Assessment',
    //     breadcrumb: [
    //       {
    //         text: 'Assessment',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/riskassessment/:id/risks',
    //   name: 'riskassessment',
    //   component: () => import('@/views/RiskAssessment/RiskResponseAssessment.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Risk Assessment',
    //     breadcrumb: [
    //       {
    //         text: 'Assessment',
    //         to: { name: 'riskassessment' },
    //       },
    //       {
    //         text: 'Risk Assessment',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/tasks',
      name: 'tasks',
      component: () => import('@/views/Tasks/Tasks.vue'),
      beforeEnter: guardRoute,
      meta: {
        
        GrcAuth:true,
        vendorAuth: true,
        // breadcrumb: [
        //   {
        //     text: 'Tasks',
        //     active: true,
        //   },
        // ],
      },
    },

    // {
    //   path: '/standards',
    //   name: 'standards',
    //   component: () => import('@/views/Standards/Standards.vue'),
    //   beforeEnter: guardRoute,
    //   meta: {
    //     pageTitle: 'Standards',
    //     breadcrumb: [
    //       {
    //         text: 'Standards',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/standards/:id/details',
    //   name: 'asset-details',
    //   component: () => import('@/views/Standards/StandardDetails.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Standard Details',
    //     breadcrumb: [
    //       {
    //         text: 'Standards',
    //         to: { name: 'standards' },
    //       },
    //       {
    //         text: 'Details',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     text: "Stats & Graphs",
        //     active: true,
        //   },
        // ],
      },
    },

    // {
    //   path: '/periodicactivities',
    //   name: 'periodicactivities',
    //   component: () => import('@/views/Periodic/PeriodicActivities.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Periodic activities',
    //     breadcrumb: [
    //       {
    //         text: 'Periodic activities',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/gapassessment',
    //   name: 'gapassessment',
    //   component: () => import('@/views/GapAssessment/GapAssessment.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Gap Assessment',
    //     breadcrumb: [
    //       {
    //         text: 'Assessment',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/gapassessment/:id/controlgapassessment',
    //   name: 'control-gap-asssessment',
    //   component: () => import('@/views/GapAssessment/ControlGapAssessment.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Control Gap Assessment',
    //     breadcrumb: [
    //       {
    //         text: 'Gap Assessment',
    //         to: { name: 'gapassessment' },
    //       },
    //       {
    //         text: 'Control Gap Assessment',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: '/gapassessment/gap-submit-requested-evidences',
    //   name: 'gap-submit-requested-evidences',
    //   component: () => import('@/views/GapAssessment/GapSubmitRequestedEvidences.vue'),
    //   beforeEnter: guardRoute,
    //   props: true,
    //   meta: {
    //     pageTitle: 'Evidence Submission',
    //     breadcrumb: [
    //       {
    //         text: 'Gap Assessment',
    //         to: { name: 'gapassessment' },
    //       },
    //       {
    //         text: 'Gap Assessment Evidence Submission',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/vendorassessments",
      name: "vendorassessments",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/VendorAssessments/VendorAssessments.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        vendorAuth: true,
        // pageTitle: "Assessments",
      },
    },

    {
      path: "/vendorassessments/:assessmentId",
      name: "vendorassessment-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/VendorAssessments/VendorAssessmentDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        vendorAuth: true,
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "vendorassessments" },
          },
          {
            text: "Assessment Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdparties",
      name: "thirdparties",
      component: () => import("@/views/ThirdPartyRisks/ThirdParties.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        breadcrumb: [
          {
            text: "Third Parties",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details",
      name: "thirdpartyrisks-details",
      component: () => import("@/views/ThirdPartyRisks/VendorDetails.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Details",
        breadcrumb: [
          {
            text: "Third Party",
            to: { name: "thirdparties" },
          },
          {
            text: "Third Party Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments",
      name: "thirdpartyrisksRA-assessments",
      component: () =>
        import("@/views/ThirdPartyRisks/ThirdPartyRisksRA/Assessments.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        breadcrumb: [
          {
            text: "Assessments",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments/new",
      name: "thirdpartyrisksRA-assessments-new",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/NewAssessmentPage.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        // pageTitle: "Assessments",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "New Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments/edit/:id",
      name: "thirdpartyrisksRA-assessments-edit",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/EditAssessmentPage.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        // pageTitle: "Assessments",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Edit Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments/:assessmentId/details",
      name: "thirdpartyrisksRA-assessment-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/AssessmentDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Assessment Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/thirdpartyrisksRA/assessments/:assessmentId/questionnaire/:questionnaireId",
      name: "thirdpartyrisksRA-assessment-questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/QuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Assessment Details",
            to: { name: "thirdpartyrisksRA-assessment-details" },
          },
          {
            text: "Questionnaire Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/inbox",
      name: "inbox",
      component: () => import("@/views/Inbox/InboxListing.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta:{
        GrcAuth:true,
        vendorAuth: true,
      }
    },
    {
      path: "/thirdpartyrisksRA/assessments/:assessmentId/vendor/:vendorId",
      name: "thirdpartyrisksRA-assessments-vendor",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/ThirdPartyResponses.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Assessment Details",
            to: { name: "thirdpartyrisksRA-assessment-details" },
          },
          {
            text: "Third Party Responses",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details/assessment/:assessmentId",
      name: "thirdpartyrisks-assessments",
      component: () => import("@/views/ThirdPartyRisks/AssessmentDetails.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Thirdparty",
            to: { name: "thirdparties" },
          },
          {
            text: "Thirdparty details",
            to: { name: "thirdpartyrisks-details" },
          },
          {
            text: "Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details/assessment/:assessmentId/questionnaire/:questionnaireId",
      name: "thirdpartyrisks-responses",
      // component: () => import('@/views/ThirdPartyRisks/QuestionnaireResponses.vue'),
      component: () =>
        import("@/views/ThirdPartyRisks/QuestionnaireAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Vendors",
            to: { name: "thirdpartyrisks" },
          },
          {
            text: "Vendor",
            to: { name: "thirdpartyrisks-details" },
          },
          {
            text: "Assessment",
            to: { name: "thirdpartyrisks-assessments" },
          },
          {
            text: "Responses",
            active: true,
          },
        ],
      },
    },

    {
      path: "/questionnaires",
      name: "questionnaires",
      component: () =>
        import("@/views/ThirdPartyRisks/Questionnaires/Questionnaires.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaires",
        breadcrumb: [
          {
            text: "Questionnaires",
            active: true,
          },
        ],
      },
    },

    {
      path: "/questionnaires/:id/details",
      name: "questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/Questionnaires/NonImportedQuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaire Details",
        breadcrumb: [
          {
            text: "Questionnaires",
            to: { name: "questionnaires" },
          },
          {
            text: "Questionnaire Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/imported-questionnaires/:id/details",
      name: "imported-questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/Questionnaires/ImportedQuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaire Details",
        breadcrumb: [
          {
            text: "Questionnaires",
            to: { name: "questionnaires" },
          },
          {
            text: "My Questionnaire Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teams",
      name: "teams",
      component: () =>
        import(
          "@/views/Teams/Teams.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Teams",
        breadcrumb: [
          {
            text: "Teams",
            to: { name: "teams" },
          },
          {
            text: "Teams list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teamsdetails/:id",
      name: "teamsdetails",
      component: () =>
        import(
          "@/views/Teams/TeamDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Team Details",
        breadcrumb: [
          {
            text: "Team Details",
            to: { name: "teamsdetails" },
          },
          {
            text: "Team Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/policies",
      name: "policies",
      component: () => import("@/views/policies/listing.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        breadcrumb: [
          {
            text: "Policies",
            active: true,
          },
        ],
      },
    },

    {
      path: "/no-features",
      name: "no-features",
      component: () => import("@/views/NoFeature.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        layout: "full",
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Users/Login.vue"),
      meta: {
        layout: "full",
      },
    },

    // {
    //   path: "/2fa-login/:id",
    //   name: "TwoFA",
    //   component: () => import("@/views/2FALogin.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },

    {
      path: "/register",
      name: "register",
      // component: () => import('@/views/Users/Register.vue'),
      component: () => import("@/views/Users/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/Users/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/Users/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/create-new-password",
      name: "create-new-password",
      props: true,
      component: () => import("@/views/Users/CreateNewPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    // {
    //   path: '/settings',
    //   name: 'settings',
    //   component: () => import('@/views/Settings/SettingsView.vue'),
    //   beforeEnter: guardRoute,
    //   meta: {
    //     pageTitle: 'App Settings',
    //     breadcrumb: [
    //       {
    //         text: 'Settings',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/no-workspaces",
      name: "no-workspaces",
      component: () => import("@/views/error/NoWorkspaces.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  console.log("From", from.path);
  console.log("To", to.path);
  next();
});

router.onError((error) => {
  console.error('Router Error:', error);
});

export default router;
